/******* screen-large.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
div.navi {
  float: left;
  display: block;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
div.sub1 {
  float: right;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 31px 30px 31px 0;
}
div.sub1 > .item {
  padding: 9px 0;
  position: relative;
}
div.sub1 > .item:after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 10px;
  height: 1px;
  background: #1A4066;
}
div.sub1 > .item.init {
  padding-top: 0;
}
div.sub1 > .item.init:after {
  display: none;
}
div.sub1 > .item.exit {
  padding-bottom: 0;
}
div.sub1 > .item > .menu {
  font-size: 12px;
  line-height: 1.16666667;
  text-decoration: none;
  color: #1A4066;
  text-transform: uppercase;
}
div.sub1 > .item > .menu.path {
  font-weight: normal;
  font-family: 'DINNextMedium', helvetica, sans-serif;
}
div.sub2 > .item {
  margin: 0 22px;
}
@media (max-width: 1140px) {
  div.sub2 > .item {
    margin: 0 18px;
  }
}
div.sub2 > .item.init {
  margin-left: 0;
}
div.sub2 > .item.exit {
  margin-right: 0;
}
div.sub2 > .item:hover > .menu,
div.sub2 > .item:focus > .menu {
  color: #0694D3;
}
div.sub2 > .item > .menu {
  font-size: 16px;
  line-height: 1.125;
  letter-spacing: 0.1px;
  text-decoration: none;
  color: #1A4066;
  position: relative;
  padding-bottom: 18px;
}
div.sub2 > .item > .menu.path {
  color: #0694D3;
}
div.sub2 > .item > .menu.path {
  font-weight: normal;
  font-family: 'DINNextMedium', helvetica, sans-serif;
}
div.sub3 {
  float: left;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  text-align: center;
  margin-right: -10000px;
  max-height: 0;
  overflow: hidden;
  transition: all 1.5s cubic-bezier(0.05, 0.8, 0.5, 1);
}
div.sub3 > .item {
  width: 100%;
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
div.sub3 > .item.init {
  margin-top: 15px;
}
div.sub3 > .item.exit {
  margin-bottom: 15px;
}
div.sub3 > .item > .menu {
  display: inline-block;
  font-size: 14px;
  line-height: 1.85714286;
  text-decoration: none;
  color: #1A4066;
}
div.sub3 > .item > .menu:hover,
div.sub3 > .item > .menu:focus {
  color: #0694D3;
}
div.sub3 > .item > .menu.path {
  font-weight: normal;
  font-family: 'DINNextMedium', helvetica, sans-serif;
  color: #0694D3;
}
div.sub2 > .item:hover div.sub3 {
  max-height: 200px;
}
div.sub2 > .item:hover div.sub3 > .item {
  opacity: 1;
  transition: all 0.6s 0.1s cubic-bezier(0.05, 0.8, 0.5, 1);
}
#home {
  height: 84px;
  margin: 12px 0 12px 30px;
}
.section--multimood {
  margin-top: 108px;
}
.cbdModule--teaserTitle {
  width: 83.5%;
  margin-left: 8.33333333%;
}
.cbdModule--teaserTitle .teaserTitle {
  width: 96.80638723;
  margin-left: 1.59680639;
  margin-right: 1.59680639;
}
.content--norm .area,
.content--norm .fakeArea {
  width: 100%;
}
.content--norm .area > .unit,
.content--norm .fakeArea > .unit,
.content--norm .area > .fakeUnit,
.content--norm .fakeArea > .fakeUnit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.content--norm .area .foot,
.content--norm .fakeArea .foot {
  margin-right: 1.59680639%;
  margin-left: 1.59680639%;
}
.content--norm .area .part,
.content--norm .fakeArea .part,
.content--norm .area .fakePart,
.content--norm .fakeArea .fakePart {
  margin-right: 1.59680639%;
  margin-left: 1.59680639%;
  width: 96.80638723%;
}
.content--norm .area .tiny,
.content--norm .fakeArea .tiny {
  width: 46.80638723%;
}
.content--norm .area > .slim,
.content--norm .fakeArea > .slim {
  width: 50%;
}
.content--norm .area > .slim .foot,
.content--norm .fakeArea > .slim .foot,
.content--norm .area > .slim .part,
.content--norm .fakeArea > .slim .part,
.content--norm .area > .slim .fakePart,
.content--norm .fakeArea > .slim .fakePart {
  margin-right: 3.19361277%;
  margin-left: 3.19361277%;
}
.content--norm .area > .slim .part,
.content--norm .fakeArea > .slim .part,
.content--norm .area > .slim .fakePart,
.content--norm .fakeArea > .slim .fakePart {
  width: 93.61277445%;
}
.content--norm .area > .slim .tiny,
.content--norm .fakeArea > .slim .tiny {
  width: 43.61277445%;
}
.content--norm .area,
.content--norm .fakeArea {
  width: 83.5%;
  margin-left: 8.33333333%;
}
.cb-layout4 .content--norm .area {
  width: 80.83333333%;
  margin-left: 9.58333333%;
}
.content--norm .area .pure.wide .tall,
.content--norm .fakeArea .pure.wide .tall {
  box-sizing: border-box;
  padding-left: 16.96606786%;
}
.content--norm .area .seam.slim {
  width: 33.33333333%;
}
.content--norm .area .seam.slim .part {
  margin-left: 4.79041916%;
  margin-right: 4.79041916%;
  width: 90.41916168%;
}
.content--norm .area .flat {
  margin-left: 1.59680639%;
  margin-right: 1.59680639%;
}
.content--norm .area .flat.wide {
  width: 96.80638723%;
}
.content--norm .area .flat.wide .part {
  margin-left: 2.0661157%;
  margin-right: 2.0661157%;
  width: 95.8677686%;
}
.content--norm .area .flat.wide .part.tiny {
  width: 45.8677686%;
}
.content--norm .area .flat.slim {
  width: 30.13972056%;
}
.content--norm .area .flat.slim .part {
  margin-left: 6.66666667%;
  margin-right: 6.66666667%;
  width: 86.66666667%;
}
.content--norm .area .edge {
  margin-left: 1.59680639%;
  margin-right: 1.59680639%;
}
.content--norm .area .edge.wide {
  width: 96.80638723%;
}
.content--norm .area .edge.wide .part {
  margin-left: 2.06185567%;
  margin-right: 2.06185567%;
  width: 95.87628866%;
}
.content--norm .area .edge.wide .part.tiny {
  width: 45.87628866%;
}
.content--norm .area .edge.slim {
  width: 30.13972056%;
}
.content--norm .area .edge.slim .part {
  margin-left: 6.62251656%;
  margin-right: 6.62251656%;
  width: 86.75496689%;
}
.unit.form .tile .name,
.fakeUnit.form .tile .name,
.unit a.capt,
.fakeUnit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.fakeUnit.form .tile .chop,
.unit.form .tile textarea,
.fakeUnit.form .tile textarea,
.unit.form .tile input.text,
.fakeUnit.form .tile input.text,
.unit.form .tile .ctrl,
.fakeUnit.form .tile .ctrl,
.unit input.capt,
.fakeUnit input.capt {
  float: right;
  width: 63%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
th.prev,
th.next {
  font-weight: normal;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #1A4066;
  color: #fff;
}
.same,
.mese .same > span {
  background-color: #1A4066;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #1A4066;
}
.mese .same {
  background: none;
}
/******* module-filter-large.less 2013-1-16 *******/
/*.beta span > .cb-hybrid,
.days td > .cb-hybrid,
.week td > .cb-hybrid,
.year td > .cb-hybrid,
.cats li > .cb-hybrid {
  text-decoration: underline;
}*/
.mese td > .cb-hybrid {
  background-color: #1d4872;
}
.mese td > .cb-hybrid:hover,
.mese td > .cb-hybrid:focus {
  background-color: #1f4d7a;
}
.mese tbody th,
.epoc td > .cb-hybrid,
.year td > .cb-hybrid,
.mese td > .cb-hybrid,
.week td > .cb-hybrid,
.days td > .cb-hybrid {
  padding: 0 0.3em;
}
.mese tbody th,
.mese tbody td {
  padding: 0 4% 0 2%;
}
.mese td > .cb-hybrid,
.mese td > span {
  padding: 0 0.1em;
}
.mese td > span {
  display: block;
}
.tabs .cb-hybrid,
.tabs .same > span,
.time .cb-hybrid,
.time .same > span,
.keys input,
.scan select {
  padding: 0;
}
.scan select {
  margin: 0;
  width: 100%;
}
.keys input {
  float: left;
  margin: 0 2% 0 0;
  padding: 0.23em;
  width: 71%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.keys button.cb-hybrid {
  display: block;
  float: left;
}
.font9 {
  font-size: 9px;
  font-size: 0.9rem;
}
.font12 {
  font-size: 12px;
  font-size: 1.2rem;
}
.font15 {
  font-size: 15px;
  font-size: 1.5rem;
}
.font18 {
  font-size: 18px;
  font-size: 1.8rem;
}
.font21 {
  font-size: 21px;
  font-size: 2.1rem;
}
.font24 {
  font-size: 24px;
  font-size: 2.4rem;
}
.font27 {
  font-size: 27px;
  font-size: 2.7rem;
}
.font30 {
  font-size: 30px;
  font-size: 3rem;
}
.font32 {
  font-size: 32px;
  font-size: 3.2rem;
}
/*# sourceMappingURL=./screen-large.css.map */